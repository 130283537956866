<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="90px"
    >
      <el-form-item label="内部名称:" prop="name">
        <el-input v-model="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="标准名称:" prop="express_name">
        <el-input v-model="formData.express_name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="市场价:" prop="market_price">
        <el-input v-model="formData.market_price" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="成本:" prop="cost_price">
        <el-input v-model="formData.cost_price" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input v-model="formData.sort" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="简码:" prop="express_name_en">
        <el-input
          v-model="formData.express_name_en"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditExpress",
  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增快递商",
      diagonStatus: "",
      labelPosition: "right",
      id: null,
      formData: {
        id: null,
        name: null,
        express_name: null,
        express_name_en: null,
        market_price: null,
        cost_price: null,
        sort: 1,
      },
      rules: {
        name: [
          { required: true, message: "内部快递名字不能为空", trigger: "blur" },
        ],
        express_name: [
          { required: true, message: "快递标准名字不能为空", trigger: "blur" },
        ],
        express_name_en: [
          { required: true, message: "简码不能为空", trigger: "blur" },
        ],
        market_price: [
          {
            required: true,
            message: "价格不能为空",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "排序不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增快递商";
        this.formData = {
          id: null,
          name: null,
          express_name: null,
          express_name_en: null,
          market_price: null,
          cost_price: null,
          sort: 1,
        };
        this.imageSrc = "";
      } else {
        this.title = "编辑快递商";
        this.formData = {
          id: this.item.id,
          name: this.item.name,
          express_name: this.item.express_name,
          express_name_en: this.item.express_name_en,
          market_price: this.item.market_price,
          sort: this.item.sort,
        };
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      const formData = this.formData;
      let data = {
        name: formData.name,
        express_name: formData.express_name,
        express_name_en: formData.express_name_en,
        cost_price: formData.cost_price,
        market_price: formData.market_price,
        sort: formData.sort,
      };
      if (this.type === "add") {
        this.handleAddComfirm(data);
        return;
      } else {
        data.id = formData.id;
        this.handleEditComfirm(data);
      }
    },
    //新增提交
    async handleAddComfirm(data) {
      const res = await this.$store.dispatch("newExpress", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(data) {
      const res = await this.$store.dispatch("editExpress", data);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>