
<template>
  <div class="wrapper">
    <express-nav @add="handleAddItem"></express-nav>
    <list :list="list" @edit="handleEditItem" @succ="handleSucc"></list>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="10"
      :total="total"
      :hide-on-single-page="true"
      @current-change="changePage"
    >
    </el-pagination>
    <pagination
      :page="page"
      :pageSize="pageSize"
      :total="total"
      @sizeChange="handlePageSizeChange"
      @pageChange="handlePageChange"
    >
    </pagination>
    <edit-express
      :show="show"
      :type="type"
      :item="item"
      @succ="handleSucc"
    ></edit-express>
  </div>
</template>

<script>
import ExpressNav from "./components/Nav";
import List from "./components/List";
import EditExpress from "./components/EditExpress";
export default {
  name: "express",
  components: { ExpressNav, List, EditExpress },
  props: {},
  data() {
    return {
      show: false,
      type: "add",
      list: [],
      pageSize: 10,
      page: 1,
      total: 0,
      item: {},
    };
  },
  watch: {},
  computed: {},
  async created() {
    //获取快递列表
    let res = await this.$store.dispatch("getAllExpress", {
      page: 1,
      pageSize: 10,
    });
    if (res.code != 0) {
      this.$message({
        message: "快递列表获取失败",
        type: "error",
      });
      return;
    }
    this.expressList = res.items;
    this.total = res.total;
  },
  mounted() {
    this.getAllExpress();
  },
  methods: {
    //获取快递商列表
    async getAllExpress() {
      const res = await this.$store.dispatch("getAllExpress");
      this.list = res.items;
      this.total = res.total;
    },
    //提交成功
    handleSucc() {
      this.getAllExpress();
    },
    //切换页面展示数量
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getAllExpress();
    },
    //切换页数
    handlePageChange(page) {
      this.page = page;
      this.getAllExpress();
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //快递页码改变事件
    async changePage(page) {
      //获取快递列表
      let data = await this.$api.expressApi.getAllExpress({
        page: page,
        pageSize: 10,
      });
      if (data.code != 0) {
        this.$message({
          message: "快递列表获取失败",
          type: "error",
        });
        return;
      }
      this.expressList = data.items;
      this.total = data.total;
    },
    //打开新增面板
    addExpress() {
      this.formData = {
        name: "",
        express_name: "",
        express_name_en: "",
        market_price: "",
        sort: "",
      };
      this.doStatus = "新增快递";
      this.dialogTableVisible = true;
    },
    //编辑代理快递
    handleExpress(scope) {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = scope[key];
      });
      this.formData["id"] = scope.id;
      this.doStatus = "编辑快递";
      this.dialogTableVisible = true;
    },
    //取消新增或编辑
    cancelAddExpress() {
      this.dialogTableVisible = false;
    },
    //确定新增或编辑
    sureAddExpress() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          if (this.doStatus == "新增快递") {
            let data = await this.$api.expressApi.newExpress(this.formData);
            if (data.code != 0) {
              if (data.code == 500) {
                this.$message({
                  message: data.msg,
                  type: "error",
                });
                return;
              }
            }
            if (data.code == 0) {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.reload();
              this.dialogTableVisible = false;
            }
          } else {
            let data = await this.$api.expressApi.editExpress(this.formData);
            if (data.code == 500) {
              this.$message({
                message: data.msg,
                type: "error",
              });
              return;
            }
            if (data.code == 0) {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.reload();
              this.dialogTableVisible = false;
            }
          }
        } else {
          this.$message({
            message: "参数缺失",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  /deep/.el-pagination {
    text-align: left;
  }
}
</style>