<template>
  <el-row :gutter="20">
    <el-col :span="3">
      <el-button type="primary" @click="handleAddItem" icon="el-icon-plus"
        >新增快递</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Nav",
  methods: {
    handleAddItem() {
      this.$emit("add");
    },
  },
};
</script>