<template>
  <table-container :list="list">
    <el-table-column prop="id" label="ID" width="50"> </el-table-column>
    <el-table-column prop="name" label="内部名称"> </el-table-column>
    <el-table-column prop="express_name" label="快递公司"> </el-table-column>
    <el-table-column prop="express_name_en" label="简码"> </el-table-column>
    <el-table-column prop="market_price" label="市场价"> </el-table-column>
    <el-table-column prop="cost_price" label="成本"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delExpress", {
        express_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>